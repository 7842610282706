import {IbanTercihiEnum} from "../uyap/IbanTercihiEnum";

export class MtsDosyaFormData {
    //MTS
    ibanTercihi: IbanTercihiEnum = IbanTercihiEnum.Avukat;
    avukatIban: string = "";
    il: string = "";
    adliye: string = "";
    alacakliEposta: string = "";
    alacakliTelefon: string = "";
    alacakliMernisAdresiKullan: boolean = false;
    mernisAdresiKullan: boolean = false;
    odemeYapilmasin: boolean = false;
    mahiyetId!: number;
    terekemi: boolean = false;
    birimAd: string = "";
    alacakliTaraf: any = {};

}