

import {Component, Vue} from "vue-property-decorator";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import MtsTakipAcFormWizard from "@/components/forms/form-wizard/eicrapro/MtsTakipAcFormWizard.vue";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, MtsTakipAcFormWizard}
})
export default class MtsTakipAc extends Vue {
  taskTuru = TaskTuru;
}
